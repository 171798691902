var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"banks-table"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"loading":_vm.loadingList,"items":_vm.availableItems,"items-per-page":-1,"id":_vm.htmlElementId.issueCardBanksList,"hide-default-footer":"","group-by":"bankGroupDisplayOrder"},scopedSlots:_vm._u([{key:`group.header`,fn:function({ toggle, items, headers, isOpen }){return [(items.length)?_c('td',{staticClass:"cursor-pointer user-select-none flex-grow-1 align-center d-flex",style:({
          paddingLeft: '67px',
          paddingRight: '16px',
          height: '60px',
        }),attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('aside',{staticClass:"d-flex align-center flex-grow-1 blue-grey--text text--darken-4"},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(items[0].bankGroupName)+" ")]),_vm._l((items[0].bankGroupCountries),function(country){return _c('BaseImg',{key:country.code,staticClass:"flex-grow-0 flex-shrink-0 ml-2",style:({ borderRadius: '50%' }),attrs:{"src":country.flagUrl,"aspect-ratio":1,"width":"20"}})})],2),_c('v-icon',{staticClass:"flex-shrink-0",style:({
            transform: isOpen ? 'rotate(180deg)' : undefined,
          }),attrs:{"color":"grey"}},[_vm._v(" $dropdown ")])],1):_vm._e()]}},{key:`item.name`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('BaseImg',{staticClass:"flex-grow-0 flex-shrink-0 mr-2 elevation-4 rounded",attrs:{"src":_vm.getFlagIconUrl(item.country),"width":"45"}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:`item.paymentSystem`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[(_vm.getCardIconSrc(item.info.paymentSystem))?_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0 elevation-3",style:({ borderRadius: '10px' }),attrs:{"src":_vm.getCardIconSrc(item.info.paymentSystem),"width":"55"}}):_vm._e(),(item.info['3ds'])?_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0 ml-2",attrs:{"src":require('@/assets/img/3ds.svg'),"width":"45"}}):_vm._e()],1)]}},{key:`item.binId`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.info.binId)+" ")]}},{key:`item.favorite`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":_vm.checkBankInFavorites(item.id, _vm.favoriteBanks)
            ? 'amber accent-3'
            : 'grey',"icon":""},on:{"click":function($event){return _vm.toggleFavoriteBank(item.id)}}},[_c('v-icon',[_vm._v(" $star ")])],1)]}},{key:`item.zipInfo`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.info.zipInfo)+" ")]}},{key:`item.recommendations`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center flex-wrap"},_vm._l((_vm.getBankRecommendations(item)),function({ name, iconSrc }){return _c('BaseImg',{key:name,staticClass:"flex-shrink-0 flex-grow-0 mr-2 my-1",attrs:{"src":iconSrc,"width":"29"}})}),1)]}},{key:`item.issueCard`,fn:function({ item }){return [_c('v-btn',_vm._g({class:[
          'px-2',
          {
            [_vm.htmlElementClassName.selectBankIssueCard]:
              _vm.checkAllowIssueCard(item),
          },
        ],attrs:{"color":!_vm.checkAllowIssueCard(item) ? 'error' : 'primary',"href":!_vm.checkAllowIssueCard(item) ? _vm.telegramSupportBotLink : undefined,"target":!_vm.checkAllowIssueCard(item) ? '_blank' : undefined,"disabled":_vm.loadingList,"ripple":false,"outlined":"","block":""}},
          _vm.checkAllowIssueCard(item)
            ? {
                click: () => {
                  _vm.onSelectBank(item);
                },
              }
            : {}
        ),[_vm._v(" "+_vm._s(!_vm.checkAllowIssueCard(item) ? _vm.$vuetify.lang.t("$vuetify.dashboard.contact_support") : _vm.$vuetify.lang.t("$vuetify.dashboard.issue_card"))+" ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }