import { Component, Mixins } from "vue-property-decorator";
import { telegramSupportBotLink } from "@config/social";

import BanksListMixin from "../../../common/mixins/banks-list.mixin";
import autoAnimate from "@formkit/auto-animate";

@Component
export default class BanksTable extends Mixins(BanksListMixin) {
  private get telegramSupportBotLink() {
    return telegramSupportBotLink;
  }

  private get tableHeaders() {
    return [
      {
        value: "favorite",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.bin_name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.payment_method"
        ),
        value: "paymentSystem",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.bin_id"),
        value: "binId",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.zip_info"),
        value: "zipInfo",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.recommendation"
        ),
        value: "recommendations",
        sortable: false,
      },
      {
        value: "issueCard",
        align: "right",
        sortable: false,
      },
    ];
  }

  private mounted() {
    const tbodyEl = this.$el.querySelector(".v-data-table__wrapper tbody") as
      | HTMLElement
      | undefined;

    if (tbodyEl) {
      autoAnimate(tbodyEl, { duration: 180 });
    }
  }
}
